<template>
  <div class="wrapIndex">
    <cardTitleCom cardTitle="开通蛋糕自选APP">
      <template slot="cardContent">
        <div class="x-x padd10" style="padding: 10px 15px;">
          <el-image style="width: 130px; height: 130px"
            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-19/bcbd029440074a20b8c7654ce56678ac.png"></el-image>
          <div class="marL20 flex1 y-b">
            <div style="line-height: 1.5">
              隼云蛋糕自选App是一款适用于实体店的便捷的自由点餐App, 替代了传统的纸质蛋糕选择相册，不仅提高蛋糕店的竞争力、提升用户体验和促进业务增长。还可以通过提前预定、
              会员计划和促销活动等方式增加用户忠诚度。随着移动技术的不断发展，蛋糕自选App将成为蛋糕店业务的不可或缺的一部分，为用户提供更多美味的选择。
            </div>
            <div>
              <div class="marB10">
                <span class="annotateGrey fontS14">价格：</span>
                <span class="fontBold fontS14" style="color: #fe5a34">￥</span>
                <span class="fontBold fontS25" style="color: #fe5a34">
                  {{ price }}</span>
                <span class="fontBold fontS14" style="color: #fe5a34">
                  {{ `/${priceLabel}` }}</span>
              </div>
              <div class="buttons">
                <div class="button" @click="goBuy">
                  {{ isBuy == "buy" ? "立即开通" : "立即续费" }}
                </div>
                <el-link type="primary" class="text-button" @click="goList">查询订单列表></el-link>
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom :hideCard="true">
      <template slot="cardContent">
        <div class="x-fc" style="padding-top: 50px;">
          <div class="flex1" style="margin-left: 100px">
            <div v-for="(item, index) in introductionList" :key="index" class="y-start marB20">
              <div class="fontBold" style="margin-bottom: 10px;">{{ item.title }}</div>
              <div style="margin-left: 8px">{{ item.content }}</div>
            </div>
          </div>
          <el-image class="marR50" style="width: 35%; height: 80%"
            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-19/f3d3f1a4585a4924b73da2090b47f311.png"></el-image>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import { getMinPay } from '@/utils'
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import {
  getOrderDetail,
  getTradeProductToRenew,
} from "@/api/O2OThird/cakeFreeSelectApp/activate";
export default {
  name: "goBuyIndex",
  components: { cardTitleCom },
  data() {
    return {
      renewObj: {},
      //       [主要功能]
      // 集云蛋糕自选APP特色:
      // 1、替代了传统的纸质蛋糕选择相册，更便捷、智能、灵活，节约了人力物力
      // 2、用户可方便的查看商品清晰的实物展示图、价格等详细介绍信息;
      // 3、便于用户的选购，并提供多种优惠购物活动，用户可参与平台限时特价活动。
      // 4、用户可以通过App浏览菜单、选择蛋糕、添加备注、上传图片，实现简单快捷的在线点餐体验。同时确保顾客可以提前预定他们所需的蛋糕，避免了供应不足的情况。
      // 5、方便统计营业额，销售金额，收银对帐，销售排行，销售月报，会员报表等。
      introductionList: [
        {
          title: "【主要功能】",
          content:
            "隼云蛋糕自选APP特色：",
        },
        {
          title: "",
          content:
            "1、替代了传统的纸质蛋糕选择相册，更便捷、智能、灵活，节约了人力物力。",
        },
        {
          title: "",
          content:
            "2、用户可方便的查看商品清晰的实物展示图、价格等详细介绍信息。",
        },
        {
          title: "",
          content:
            "3、便于用户的选购，并提供多种优惠购物活动，用户可参与平台限时特价活动。",
        },
        {
          title: "",
          content:
            "4、用户可以通过App浏览菜单、选择蛋糕、添加备注、上传图片，实现简单快捷的在线点餐体验。同时确保顾客可以提前预定他们所需的蛋糕，避免了供应不足的情况。",
        },
        {
          title: "",
          content:
            "5、方便统计营业额，销售金额，收银对帐，销售排行，销售月报，会员报表等。",
        },

      ],
      isBuy: "buy",
      price: "---",
      priceLabel: ''
    };
  },
  mounted() {
    // 获取价格
    this.getOrder();
  },
  methods: {
    async getOrder() {
      try {
        const { data } = await this.getDicts('product_years_package_mode')
        const yearsData = data || []
        //判断是新增还是续费
        const res1 = await getTradeProductToRenew()
        if (res1.type == 'renew') {
          this.renewObj = res1.data
        }
        this.isBuy = res1.type
        const res = await getOrderDetail()
        console.log('res', res)
        const res2 = getMinPay(res.data, res1.type, yearsData)
        console.log('res2', res2)
        this.price = res2.pay
        this.priceLabel = res2.text
      } catch (error) {
        console.log("priceArr error ", error);
      }
    },
    goBuy() {
      if (this.isBuy == 'buy') {
        this.$emit('update:active', 2)
        this.$emit('resume', {})
      } else {
        let option = {
          productId: this.renewObj.productId,
          productBookDetailIds: this.renewObj.productBookDetailId ? [this.renewObj.productBookDetailId] : [],
          tenantId: this.$store.state.user.userinfo.tenantId
        }
        this.$emit('activeInfo', option)
        this.$emit('resume', {})
      }
    },
    goList() {
      this.$emit("update:active", 3);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapIndex {
  height: 100%;
  padding: 6px 10px 1px 10px;
  ::v-deep .cardContent {
    padding: 10px;
  }

  .buttons {
    display: flex;
    align-items: flex-end;

    .button {
      width: 170px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #f2f2f2;
      border-radius: 24px;
      font-size: 14px;
      color: #ffffff;
      background-color: #ff9e40;
      overflow: hidden;
      cursor: pointer;
    }

    .text-button {
      margin-left: 20px;
      font-size: 14px;
      height: 18px;
      margin-bottom: 10px;
    }
  }
}
</style>
