<template>
  <div class="wrap">
    <topOperatingButton
      :isSubmitAddBtn="false"
      :isAuditBillBtn="false"
      :isQuitBtn="false"
      @submitForm="submitForm"
      id="topOperatingButton"
    />
    <cardTitleCom cardTitle="蛋糕自选首页" style="margin-bottom: 0px; width: 100%">
      <template slot="cardContent">
        <div class="marT20 x-center">
          <div class="flex1 x-c-end">
            <ConterPreview class="conterPreview">
              <template slot="topContent">
                <div @click="upActive(0)" class="cursorP">
                  <LeftTemplate v-model="homeForm.templateContent[0]" />
                </div>
              </template>
              <template slot="bottomContent">
                <div @click="upActive(1)" class="cursorP">
                  <LeftTemplate v-model="homeForm.templateContent[1]" />
                </div>
              </template>
            </ConterPreview>
          </div>
          <div style="width: 50%">
            <div class="fontBold x-x">
              <span class="marR20">{{ `当前类型：` }}</span>
              <el-radio-group v-model="active" style="margin-bottom: 30px">
                <el-radio-button :label="0">轮播图片</el-radio-button>
                <el-radio-button :label="1">分类图片</el-radio-button>
              </el-radio-group>
            </div>
            <RightStatue
              class="rightStatue flex1"
              v-model="homeForm.templateContent[active]"
              @setTemp="getTemp"
            />
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import LeftTemplate from "./components/leftTemplate.vue";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import RightStatue from "./components/rightStatue";
import ConterPreview from "./components/conterPreview";
import {
  getDetail,
  saveOrUp,
  templateList
} from "@/api/O2OThird/cakeFreeSelectApp/homePage";
export default {
  name: "HomePage",
  components: {
    LeftTemplate,
    ConterPreview,
    RightStatue,
    topOperatingButton,
    cardTitleCom,
  },
  data() {
    return {
      active: 0,
      templateId: '',
      homeForm: {
        templateContent: [
          {
            name: "轮播图片",
            style: {
              type: "swiper", // singleton: 一行一个 ， swiper：轮播 ， roll： 滚动
              imgList: [
                {
                  name: "默认",
                  url: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-04-10/f019d47f3d964ef8b437506cbe41e68c.png", // 图片链接
                  fileSize: 12,
                },
                {
                  name: "默认",
                  url: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-05-23/d314da8a02344438a4ea336528de6b54.png", // 图片链接
                  fileSize: 12,
                },
              ],
              
              imgStyle: "original", // 图片样式 常规：original  ，投影：projection
              cornerStyle: "square", // 边角样式 方角： square   圆角： round
              imgHeight: 200, // 图片高度
              borderRadius: 8, // 圆角样式
              pageBackGauge: 0, // 页面边距
              imgbackGuge: 0, // 图片边距
              imgNum: 1, // 图片数量
              interval: 3000,
              showTitle: true,
              isBold: true,
              alignment: true,
              active: 0,
              typeList: [
                { name: "yihangyige", title: "一行一个", type: "singleton" },
                { name: "duotulunbo", title: "轮播海报", type: "swiper" },
              ],
            },
          },
          
          {
            name: "分类图片",
            style: {
              type: "classification", // classification: 一行一个 ， swiper：轮播 ， roll： 滚动
              imgList: [
                {
                  name: "默认",
                  url: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-05-24/72dd68d9ef324ca99e2b9d70d1a6621f.jpg", // 图片链接
                  fileSize: 12,
                },
                {
                  name: "默认",
                  url: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-05-24/72dd68d9ef324ca99e2b9d70d1a6621f.jpg", // 图片链接
                  fileSize: 12,
                },
                {
                  name: "默认",
                  url: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-05-24/72dd68d9ef324ca99e2b9d70d1a6621f.jpg", // 图片链接
                  fileSize: 12,
                },
                {
                  name: "默认",
                  url: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-05-24/72dd68d9ef324ca99e2b9d70d1a6621f.jpg", // 图片链接
                  fileSize: 12,
                },
                {
                  name: "默认",
                  url: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-05-24/72dd68d9ef324ca99e2b9d70d1a6621f.jpg", // 图片链接
                  fileSize: 12,
                },
                {
                  name: "默认",
                  url: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-05-24/72dd68d9ef324ca99e2b9d70d1a6621f.jpg", // 图片链接
                  fileSize: 12,
                },
              ],
              imgStyle: "original", // 图片样式 常规：original  ，投影：projection
              cornerStyle: "square", // 边角样式 方角： square   圆角： round
              imgHeight: 140, // 图片高度
              borderRadius: 8, // 圆角样式
              pageBackGauge: 10, // 页面边距
              marT: 0, // 图片上边距
              marB: 10, // 图片下边距
              imgNum: 1, // 图片数量
              interval: 3000,
              showTitle: true,
              isBold: true,
              alignment: true,
              active: 1,
              typeList: [
                { name: "yihangyige", title: "一行一个", type: "classification" },
                { name: "lianghangpailie", title: "一行两个", type: "lianghangpailie" },
                { name: "sanhangpailie", title: "一行三个", type: "sanhangpailie" },
                { name: "datuxianshi", title: "大图模式", type: "datuxianshi" },
                { name: "hengxianggundong", title: "横向滚动", type: "hengxianggundong" },
              ],
            },
          },
        ],
      },
    };
  },
  async created() {
    const res = await templateList();
    this.templateId = res.rows[0].templateId
  
    const data = await getDetail(res.rows[0].templateId);
    if(data) {
      this.homeForm.templateContent = JSON.parse(data.data.templateContent)
    }
    // this.homeForm = res.data || {};
    // this.active = 0;
    // // JSON.parse(JSON.stringify(a))
    // if (this.homeForm.templateContent) {
    //   this.homeForm.templateContent =
    //     JSON.parse(this.homeForm.templateContent) || [];
    // } else {
      // this.homeForm.templateContent = [
      //   {
      //     name: "顶部图片",
      //     style: {
      //       type: "singleton", // singleton: 一行一个 ， swiper：轮播 ， roll： 滚动
      //       imgList: [
      //         {
      //           name: "默认",
      //           url: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-04-10/f019d47f3d964ef8b437506cbe41e68c.png", // 图片链接
      //           fileSize: 12,
      //         },
      //         // {
      //         //   url:
      //         //     "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-05-13/ee397f82205e45afa9a12201ae1b867f.jpg", // 图片链接
      //         //   hint: "", // 图片提示信息
      //         //   path: "", // 跳转链接
      //         // },
      //       ],
      //       imgStyle: "original", // 图片样式 常规：original  ，投影：projection
      //       cornerStyle: "square", // 边角样式 方角： square   圆角： round
      //       imgHeight: 200, // 图片高度
      //       pageBackGauge: 0, // 页面边距
      //       imgbackGuge: 0, // 图片边距
      //       imgNum: 1, // 图片数量
      //       interval: 3000,
      //     },
      //   },
      //   {
      //     name: "底部图片",
      //     style: {
      //       type: "singleton", // singleton: 一行一个 ， swiper：轮播 ， roll： 滚动
      //       imgList: [
      //         {
      //           name: "默认",
      //           url: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-04-11/90ea122a7be34190bd7b0f5ad63938ea.jpg",
      //           fileSize: 12,
      //         },
      //         {
      //           name: "默认",
      //           url: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-04-10/f6d619251d0449cba61284acafd57bbd.jpg",
      //           fileSize: 12,
      //         },
      //       ],
      //       imgStyle: "original", // 图片样式 常规：original  ，投影：projection
      //       cornerStyle: "square", // 边角样式 方角： square   圆角： round
      //       imgHeight: 140, // 图片高度
      //       borderRadius: 8, // 圆角样式
      //       pageBackGauge: 10, // 页面边距
      //       marT: 0, // 图片上边距
      //       marB: 10, // 图片下边距
      //       imgNum: 1, // 图片数量
      //       interval: 3000,
      //     },
      //   },
      // ];
    // }
    // console.log("templateContent", this.homeForm.templateContent);
  },
  methods: {
    upActive(index) {
      this.active = index;
      this.$message.success("切换类型成功");
      this.$nextTick()
    },
    getTemp(data) {
      this.homeForm.templateContent[this.active] = data;
    },
    /** 提交按钮 */
    async submitForm() {
      let form = {
        ...this.homeForm,
        templateName: "首页",
        templateContent: JSON.stringify(this.homeForm.templateContent),
      };
      if (this.templateId) {
        form.templateId = this.templateId;
      }
      await saveOrUp(form);
      this.$modal.msgSuccess("保存成功");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .conterPreview {
    width: 375px;
    height: 667px;
    margin-right: 100px;
    background-color: #f7f7f7;
    overflow: auto; /* 垂直方向滚动 */
  }
  .rightStatue {
    max-width: 500px;
    height: calc(100vh - 200px);
    overflow: auto; /* 垂直方向滚动 */
  }
}
::-webkit-scrollbar {
  display: none;
}
</style>
