var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        { staticClass: "phoneStartBox" },
        [
          _c("el-image", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { src: require("@/assets/images/phoneStart.png") },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "navBar x-f" },
        [
          _c("i", {
            staticClass: "x-f-start",
            staticStyle: { "font-size": "25px", width: "90px" },
          }),
          _c("span", { staticClass: "title" }, [_vm._v("首页")]),
          _c("el-image", {
            staticClass: "capsule x-f-end",
            attrs: { src: require("@/assets/images/capsule.png") },
          }),
        ],
        1
      ),
    ]),
    _c("div", [
      _vm._m(0),
      _vm.$slots.topContent ? _c("div", [_vm._t("topContent")], 2) : _vm._e(),
      _c(
        "div",
        { staticClass: "x-ac", staticStyle: { padding: "10px" } },
        _vm._l(_vm.diningMethodsList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "x-fc eatIn",
              style: {
                border:
                  index == _vm.isDine
                    ? "2px solid #fd5b34"
                    : "2px solid #cccccc",
                marginRight: index == 0 ? "10px" : "0",
              },
              on: {
                click: function ($event) {
                  return _vm.switchDiningMethods(index)
                },
              },
            },
            [
              _c("img", {
                staticStyle: { width: "35px", height: "35px" },
                attrs: { src: item.url },
              }),
              _c(
                "div",
                {
                  staticClass: "eatInText",
                  style: { color: index == _vm.isDine ? "#fd5b34" : "#A6A6A6" },
                },
                [_vm._v(" " + _vm._s(item.text) + " ")]
              ),
            ]
          )
        }),
        0
      ),
      _vm.$slots.bottomContent
        ? _c("div", [_vm._t("bottomContent")], 2)
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status x-bc" }, [
      _c("div", { staticClass: "x-fc shop" }, [
        _c("img", {
          staticStyle: { width: "20px", height: "20px" },
          attrs: { src: require("@/assets/images/shop.svg"), alt: "#" },
        }),
        _c("span", { staticClass: "marL10" }, [
          _vm._v(" 隼云科技 （默认门店）"),
        ]),
      ]),
      _c("div", { staticClass: "tableCode" }, [_vm._v("1桌2号")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }