<template>
  <div>
    <div>
      <div class="phoneStartBox">
        <el-image
          style="width: 100%; height: 100%"
          :src="require('@/assets/images/phoneStart.png')"
        ></el-image>
      </div>
      <div class="navBar x-f">
        <i class="x-f-start" style="font-size: 25px; width: 90px"></i>
        <span class="title">首页</span>
        <el-image
          class="capsule x-f-end"
          :src="require('@/assets/images/capsule.png')"
        ></el-image>
      </div>
    </div>
    <div>
      <div class="status x-bc">
        <div class="x-fc shop">
          <img
            src="@/assets/images/shop.svg"
            alt="#"
            style="width: 20px; height: 20px"
          />
          <span class="marL10"> 隼云科技 （默认门店）</span>
        </div>
      </div>
      <div v-if="$slots.topContent">
        <slot name="topContent"></slot>
      </div>
      <div  style="padding: 10px">
        <div>商品分类</div>
      </div>
      <div v-if="$slots.bottomContent">
        <slot name="bottomContent"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "conterPreview",
  data() {
    return {
      isDine: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.status {
  padding: 10px;
  border-bottom: 2px solid #f2f2f2;
  background-color: #ffffff;
  .shop {
    font-size: 14px;
    font-weight: bold;
  }

  .tableCode {
    font-size: 28rpx;
  }
}
.phoneStartBox {
  width: 100%;
  .el-image {
    display: block;
  }
}
.navBar {
  width: 100%;
  height: 44px;
  padding: 0 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee;

  .title {
    font-size: 18px;
    font-weight: 550;
    margin: 0 auto;
  }

  .capsule {
    width: 90px;
    height: 30px;
  }
}

.singleton,
.swiper {
  .el-image {
    display: block;
  }
}
.eatIn {
  width: calc(50% - 5px);
  border: 2px solid #fd5b34;
  border-radius: 8px;
  height: 100px;

  .eatInText {
    margin-left: 5px;
    font-size: 14px;
    color: #a6a6a6;
  }
}
</style>
