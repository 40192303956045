<template>
  <div class="wrapIndex">
    <cardTitleCom cardTitle="开通点餐小程序">
      <template slot="cardContent">
        <div class="x-x padd10" style="padding: 10px 15px">
          <el-image style="width: 130px; height: 130px"
            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-04-23/eb6aa8364ce94a6891af6ec6ac90d7ec.png"></el-image>
          <div class="marL20 flex1 y-b">
            <div style="line-height: 1.5">
              隼云点餐小程序是隼云针对微信平台推出的点餐小程序。用户可以随时随地在该小程序中浏览店内的菜单，选择喜欢的食物，可以提前点单或者到店点餐。还支持微信支付和会员优惠券等功能。省去了繁琐的纸质菜单填写过程，帮助餐饮商家提高了运营效率和服务质量，大大减少开店成本，节约人力物力。
            </div>
            <div>
              <div class="marB10">
                <span class="annotateGrey fontS14">价格：</span>
                <span class="fontBold fontS14" style="color: #fe5a34">￥</span>
                <span class="fontBold fontS25" style="color: #fe5a34">
                  {{ price }}</span>
                <span class="fontBold fontS14" style="color: #fe5a34">
                  {{ `/${priceLabel}` }}</span>
              </div>
              <div class="buttons">
                <div class="button" @click="goBuy">
                  {{ isBuy == "buy" ? "立即开通" : "立即续费" }}
                </div>
                <el-link type="primary" class="text-button" @click="goList">查询订单列表></el-link>
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom :hideCard="true">
      <template slot="cardContent">
        <div class="x-fc">
          <div class="flex1" style="margin-left: 100px">
            <div v-for="(item, index) in introductionList" :key="index" class="y-start marB20">
              <div class="fontBold">{{ item.title }}</div>
              <div style="margin-left: 8px">{{ item.content }}</div>
            </div>
          </div>
          <el-image class="marR50" style="width: 41%; height: 80%"
            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-04-23/893c8ec54a90478a89b04f16956c554d.png"></el-image>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import { getMinPay } from '@/utils'
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import {
  getOrderDetail,
  getTradeProductToRenew,
} from "@/api/O2OThird/orderMealWxa/activate";
export default {
  name: "goBuyIndex",
  components: { cardTitleCom },
  data() {
    return {
      renewObj: {},
      introductionList: [
        {
          title: "【主要功能】",
          content:
            "菜品管理、桌台管理、规格管理、售罄管理、订单管理、厨单打印、提前点餐、扫码点餐等。",
        },
        {
          title: "【店内点餐】",
          content:
            "客人用微信扫描店内桌台码码即可实现点单，查消费，买单支付结帐等操作，节省人力，提高翻桌率，减少客人等待时间。",
        },
        {
          title: "【桌台管理】",
          content: "管理桌台的名称，容纳人数，生成桌台二维码 。",
        },
        {
          title: "【会员管理】",
          content: "管理会员档案，余额，积分。充值流水统计，积分流水统计。",
        },
        {
          title: "【营业报表】",
          content:
            "统计营业额，销售金额，收银对帐，销售排行，销售月报，会员报表等。",
        },
        {
          title: "【打印机管理】",
          content: "添加蓝牙打印机，可打印厨房小票。",
        },
        {
          title: "【数据存储】",
          content: "采用阿里云服务器，数据安全不丢失，更换设备数据自动同步。",
        },
      ],
      isBuy: "buy",
      price: "---",
      priceLabel: ''
    };
  },
  mounted() {
    // 获取价格
    this.getOrder();
  },
  methods: {
    async getOrder() {
      try {
        const { data } = await this.getDicts('product_years_package_mode')
        const yearsData = data || []
        //判断是新增还是续费
        const res1 = await getTradeProductToRenew()
        if (res1.type == 'renew') {
          this.renewObj = res1.data
        }
        this.isBuy = res1.type
        const res = await getOrderDetail()
        console.log('res', res)
        const res2 = getMinPay(res.data, res1.type, yearsData)
        console.log('res2', res2)
        this.price = res2.pay
        this.priceLabel = res2.text
      } catch (error) {
        console.log("priceArr error ", error);
      }
    },
    goBuy() {
      if (this.isBuy == 'buy') {
        this.$emit('update:active', 2)
        this.$emit('resume', {})
      } else {
        let option = {
          productId: this.renewObj.productId,
          productBookDetailIds: this.renewObj.productBookDetailId ? [this.renewObj.productBookDetailId] : [],
          tenantId: this.$store.state.user.userinfo.tenantId
        }
        this.$emit('activeInfo', option)
        this.$emit('resume', {})
      }
    },
    goList() {
      this.$emit("update:active", 3);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapIndex {
  height: 100%;
  padding: 6px 10px 1px 10px;
  ::v-deep .cardContent {
    padding: 10px;
  }

  .buttons {
    display: flex;
    align-items: flex-end;

    .button {
      width: 170px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #f2f2f2;
      border-radius: 24px;
      font-size: 14px;
      color: #ffffff;
      background-color: #ff9e40;
      overflow: hidden;
      cursor: pointer;
    }

    .text-button {
      margin-left: 20px;
      font-size: 14px;
      height: 18px;
      margin-bottom: 10px;
    }
  }
}
</style>
