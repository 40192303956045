<!-- 蛋糕自选门店 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import TablePage from '@/components/tablePage/index.vue'
import {
  cakefreeselectShopListAPI,
  cakefreeselectBatchLaunch,
  cakefreeselectSyncBasicShopInfoAPI
} from '@/api/O2OThird/cakeFreeSelectApp/shop'
export default {
  name: 'shop',
  components: { TablePage },
  data () {
    return {
      options: {
        tableTitle: true,
        listNo: true,
        mutiSelect: true,
        loading: true,
        check: [],
        title: '蛋糕自选门店',
        tabColumnType: 'listing',
        rowKey: 'shopId',
        search: [
          {
            type: 'input',
            tip: '门店编码、门店名称',
            model: '',
            clearable: true,
            filter: 'query'
          },
          {
            type: 'button',
            tip: '查询',
            btnType: 'primary',
            click: 'search',
            isRestore: true
          }
        ],
        tabsColumns: [
          {
            title: '已上架',
            getListApi: cakefreeselectShopListAPI,
            defaultBody: { status: 0 },
            type: 'listing',
            isHideRestore: true,
            buttons: [
              {
                click: 'unmount',
                label: '下架',
                type: 'danger',
                alertText: '确认要下架选中的门店吗？'
              },
              {
                click: 'refresh',
                right: true,
                label: '刷新',
                icon: 'el-icon-refresh',
                type: ''
              }
            ],
            columns: [
              {
                type: 'link',
                click: 'update',
                prop: 'shopNo',
                label: '门店编码',
                minWidth: 150
              },
              { prop: 'shopName', label: '门店名称', minWidth: 150 },
              {
                prop: 'shopImageUrl',
                type: 'image',
                label: '门店logo',
                minWidth: '100'
              },
              {
                prop: 'linkEmployeeTel',
                label: '电话',
                minWidth: 120
              },
              {
                prop: 'address',
                label: '门店地址',
                minWidth: 155,
                formatter: (model, v) =>
                  `${v?.shopAddress || ''}`
              },
              {
                prop: 'businessHours',
                label: '营业时间',
                minWidth: 155,
                formatter: v =>
                  v == 0
                    ? '全天'
                    : v == 1
                    ? '每天重复'
                    : v == 2
                    ? '每周重复'
                    : '全天'
              },
              {
                prop: 'status',
                label: '上下架状态',
                minWidth: 80,
                formatter: v => (v == 0 ? '上架' : v == 1 ? '下架' : '')
              },
              { prop: 'businessStatusName', label: '营业状态', minWidth: 120 }
            ]
          },
          {
            title: '未上架',
            getListApi: cakefreeselectShopListAPI,
            defaultBody: { status: 1 },
            type: 'delist',
            buttons: [
              {
                click: 'unmount',
                label: '上架',
                type: 'success',
                alertText: '确认要上架选中的门店吗？'
              },
              {
                click: 'synchronization',
                label: '同步',
                noRule: true,
                type: 'primary'
              },
              {
                click: 'refresh',
                right: true,
                label: '刷新',
                icon: 'el-icon-refresh',
                type: ''
              }
            ],
            columns: [
              {
                type: 'link',
                click: 'update',
                prop: 'shopNo',
                label: '门店编码',
                minWidth: 150
              },
              { prop: 'shopName', label: '门店名称', minWidth: 150 },
              {
                prop: 'shopImageUrl',
                type: 'image',
                label: '门店logo',
                minWidth: '100'
              },
              {
                prop: 'linkEmployeeTel',
                label: '电话',
                minWidth: 120
              },
              {
                prop: 'address',
                label: '门店地址',
                minWidth: 155,
                formatter: (model, v) =>
                  `${v?.shopAddress || ''}`
              },
              {
                prop: 'businessHours',
                label: '营业时间',
                minWidth: 155,
                formatter: v =>
                  v == 0
                    ? '全天'
                    : v == 1
                    ? '每天重复'
                    : v == 2
                    ? '每周重复'
                    : '全天'
              },
              {
                prop: 'status',
                label: '上下架状态',
                minWidth: 80,
                formatter: v => (v == 0 ? '上架' : v == 1 ? '下架' : '')
              },
              { prop: 'businessStatusName', label: '营业状态', minWidth: 120 }
            ]
          }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'unmount':
          try {
            this.options.loading = true
            await cakefreeselectBatchLaunch({
              status: this.options.tabColumnType == 'listing' ? 1 : 0,
              shopIds: this.options.check.map(v => v.shopId)
            })
            await this.$refs.tablePage.handleEvent('getList')
            this.$message.success(
              this.options.tabColumnType == 'listing' ? '下架成功' : '上架成功'
            )
          } catch (error) {
            this.options.loading = false
          }
          break
        case 'update':
          this.$router.push({
            path: '/O2OThird/cakeFreeSelectApp/shopEdit',
            query: {
              type: 'cake',
              shopId: row.shopId
            }
          })
          break
        case 'synchronization':
          try {
            this.options.loading = true
            await cakefreeselectSyncBasicShopInfoAPI()
            await this.$refs.tablePage.getList()
            this.$message.success('同步成功')
          } catch (error) {
            this.options.loading = false
          }
          break
        default:
          break
      }
    }
  }
}
</script>
<style scoped lang="scss"></style>
