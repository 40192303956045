<template>
  <div class="guanggao padT15">
    <div class="x-bc typeList">
      <div
        class="y-c item cursorP"
        v-for="(item, index) in typeList"
        :key="index"
      >
        <div
          class="iconBox x-c"
          :class="[data.style.type == item.type ? 'iconBox2' : '']"
          @click="clickType(item.type, index)"
        >
          <iconpark-icon
            :name="item.name"
            size="28px"
            :color="data.style.type == item.type ? '#1458cc' : '#333333'"
          ></iconpark-icon>
        </div>
        <span
          class="marT10"
          :style="{
            color: data.style.type == item.type ? '#1458cc' : '#333333',
          }"
          >{{ item.title }}</span
        >
      </div>
    </div>
    <el-divider v-if="data.style.active == 0"></el-divider>
    <div class="addImg" v-if="data.style.active == 0">
      <div class="y-start">
        <span>添加图片</span>
        <span style="color: #ababab">建议图片尺寸宽度750，高度不限</span>
      </div>
      <AccessoryUpload
        :title="''"
        :limit="Number(10)"
        listType="picture-card"
        @getFileItems="getFileItemsData"
        @delFileItems="delFileItems"
        :fileList="data.style.imgList"
      ></AccessoryUpload>
    </div>
    <el-divider></el-divider>
    <div>
      <el-form :model="data" @submit.native.prevent label-position="left">
        <div v-if="data.style.type == 'roll'">
          <div class="x-f">屏幕显示数量</div>
          <el-form-item label="图片样式" label-width="80px">
            <div class="x-bc">
              <div></div>
              <el-select v-model="data.style.imgNum" placeholder="请选择">
                <el-option
                  v-for="item in imgNumList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-divider></el-divider>
        </div>
        <el-form-item
          label="切换速度"
          label-width="80px"
          v-if="['swiper', 'roll'].includes(data.style.type)"
        >
          <div class="x-bc">
            <div></div>
            <el-select v-model="data.style.interval" placeholder="请选择">
              <el-option
                v-for="item in intervalList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <!-- <el-form-item label="图片高度（单位:px）" v-if="data.style.active == 0" label-width="150px">
          <el-input
            :min="50"
            :max="800"
            maxlength="3"
            @change="selectborder"
            v-model.number="data.style.imgHeight"
            size="mini"
          />
        </el-form-item> -->
        <el-form-item label="商品名称" v-if="data.style.active == 1" label-width="100px">
            <el-radio v-model="data.style.showTitle" :label="true">显示</el-radio>
            <el-radio v-model="data.style.showTitle" :label="false">不显示</el-radio>
        </el-form-item>
        <el-form-item label="文本样式" v-if="data.style.active == 1" label-width="100px">
            <el-radio v-model="data.style.isBold" :label="true">加粗</el-radio>
            <el-radio v-model="data.style.isBold" :label="false">常规</el-radio>
        </el-form-item>
        <el-form-item label="文本对齐" v-if="data.style.active == 1" label-width="100px">
            <el-radio v-model="data.style.alignment" :label="true">左对齐</el-radio>
            <el-radio v-model="data.style.alignment" :label="false">居中对齐</el-radio>
        </el-form-item>
        <el-form-item label="圆角样式" label-width="100px">
          <div class="x-f">
            <el-slider
              style="width: 300px"
              :max="100"
              @change="selectborder(data.style.borderRadius, 'radius')"
              v-model="data.style.borderRadius"
              show-input
            ></el-slider>
          </div>
        </el-form-item>
        <el-form-item label="图片左右边距" label-width="100px">
          <div class="x-f">
            <el-slider
              style="width: 300px"
              :max="30"
              v-model="data.style.pageBackGauge"
              show-input
            ></el-slider>
          </div>
        </el-form-item>
        <el-form-item label="图片上边距" label-width="100px">
          <div class="x-f">
            <el-slider
              style="width: 300px"
              :max="30"
              v-model="data.style.marT"
              show-input
            ></el-slider>
          </div>
        </el-form-item>
        <el-form-item label="图片下边距" label-width="100px">
          <div class="x-f">
            <el-slider
              style="width: 300px"
              :max="30"
              v-model="data.style.marB"
              show-input
            ></el-slider>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import AccessoryUpload from "@/views/components/accessoryUpload/index.vue"; //上传图片
export default {
  name: "rightStatue",
  components: { AccessoryUpload },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  model: { prop: "value", event: "modelValue" },
  computed: {
    data: {
      get() {
        console.log(this.value, 'value');
        return this.value;
      },
      set(e) {
        this.$emit("modelValue", e);
      },
    },
    typeList() {
      let { typeList } = this.data.style;
      return typeList;
    },
  },
  data() {
    return {
      openLink: false, // 选择链接弹窗
      showDialog: false, // 是否显示图库弹框
      // typeList: [
      //   { name: "yihangyige", title: "一行一个", type: "singleton" },
      //   { name: "yihangyige", title: "一行一个", type: "classification" },
      //   { name: "lianghangpailie", title: "一行两个", type: "lianghangpailie" },
      //   { name: "sanhangpailie", title: "一行三个", type: "sanhangpailie" },
      //   { name: "datuxianshi", title: "大图模式", type: "datuxianshi" },
      //   { name: "hengxianggundong", title: "横向滚动", type: "hengxianggundong" },
      //   { name: "duotulunbo", title: "轮播海报", type: "swiper" },
      // ],
      imgNumList: [
        { value: 1, label: "一张图片" },
        { value: 2, label: "二张图片" },
        { value: 3, label: "三张图片" },
        { value: 4, label: "四张图片" },
        { value: 5, label: "五张图片" },
      ],
      intervalList: [
        { value: 1000, label: "1秒" },
        { value: 2000, label: "2秒" },
        { value: 3000, label: "3秒" },
        { value: 4000, label: "4秒" },
        { value: 5000, label: "5秒" },
      ],
      imgStyleList: [
        { name: "常规", type: "original" },
        { name: "投影", type: "projection" },
      ],
      cornerStyleList: [
        { name: "方角", type: "square" },
        { name: "圆角", type: "round" },
      ],
    };
  },
  methods: {
    //获取上传的图片数据
    getFileItemsData(value) {
      console.log("value", value);
      console.log("data", this.data);
      this.data.style.imgList.push({
        name: value.fileName,
        url: value.accessUrl,
        fileSize: value.fileSize,
      });
      this.templateMsg();
    },
    //删除上传的图片
    delFileItems(file) {
      console.log("file", file);
      console.log("style.imgList", this.data.style.imgList);
      this.data.style.imgList = this.data.style.imgList.filter(
        (item) => item.url != file.url
      );
      this.templateMsg();
    },
    templateMsg() {
      this.$emit("setTemp", this.data);
    },
    clickType(type, index) {
      this.data.style.type = type;
      this.templateMsg();
    },
    // 图片样式
    setImgStyle(type, index) {
      this.data.style.imgStyle = type;
      this.templateMsg();
    },
    // 圆角数值
    selectborder(type, index) {
      this.templateMsg();
    },
    // 边角样式
    setCornerStyle(type, index) {
      console.log("边角样式:", type);
      this.data.style.cornerStyle = type;
      this.templateMsg();
    },
  },
};
</script>

<style lang="scss" scoped>
.typeList {
  width: 100%;
  padding: 0 30px;

  .item {
    color: #989898;

    .iconBox {
      width: 60px;
      height: 60px;
      border: 1px solid #e5e5e5;
      padding: 20px;
      background: #f5f5f5;
    }

    .iconBox2 {
      border: 1px solid #1458cc;
      background: #d5e2f3;
    }
  }
}

.addImg {
  .item {
    .row {
      width: 100%;

      .imgBox {
        width: 100px;
        height: 100px;
        border: 1px solid #e5e5e5;
        color: #155bd4;
        font-size: 12px;
        background: #f2f4f6;

        .img {
          width: 100%;
          height: 100%;
        }
      }

      .urlBtn {
        background: #1c75f4;
        color: #ffffff;
      }

      .selectPath {
        width: 130px;
        height: 330px;
        background: #ffffff;
        border-radius: 5px;
        padding: 5px;
        box-shadow: 0 5px 5px 5px #ababab;
      }
    }
  }

  .addBtnBox {
    width: 100%;

    .addBtn {
      padding: 10px 20px;
      background: #eeeeee;
    }
  }
}

.lable {
  margin-right: 16px;
  font-size: 14px;
  color: #d3d3d3;
  line-height: 18px;
  white-space: nowrap;
}

.btnListBox {
  border-radius: 5px;

  .item {
    width: 48px;
    height: 30px;
    border: 1px solid #ebedf0;
    //padding: 0 20px;
    cursor: pointer;
  }

  .borderColor {
    border: 1px solid #1c75f4;
  }
}
</style>
