var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapIndex" },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "开通蛋糕自选APP" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              {
                staticClass: "x-x padd10",
                staticStyle: { padding: "10px 15px" },
              },
              [
                _c("el-image", {
                  staticStyle: { width: "130px", height: "130px" },
                  attrs: {
                    src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-19/bcbd029440074a20b8c7654ce56678ac.png",
                  },
                }),
                _c("div", { staticClass: "marL20 flex1 y-b" }, [
                  _c("div", { staticStyle: { "line-height": "1.5" } }, [
                    _vm._v(
                      " 隼云蛋糕自选App是一款适用于实体店的便捷的自由点餐App, 替代了传统的纸质蛋糕选择相册，不仅提高蛋糕店的竞争力、提升用户体验和促进业务增长。还可以通过提前预定、 会员计划和促销活动等方式增加用户忠诚度。随着移动技术的不断发展，蛋糕自选App将成为蛋糕店业务的不可或缺的一部分，为用户提供更多美味的选择。 "
                    ),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "marB10" }, [
                      _c("span", { staticClass: "annotateGrey fontS14" }, [
                        _vm._v("价格："),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "fontBold fontS14",
                          staticStyle: { color: "#fe5a34" },
                        },
                        [_vm._v("￥")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "fontBold fontS25",
                          staticStyle: { color: "#fe5a34" },
                        },
                        [_vm._v(" " + _vm._s(_vm.price))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "fontBold fontS14",
                          staticStyle: { color: "#fe5a34" },
                        },
                        [_vm._v(" " + _vm._s("/" + _vm.priceLabel))]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "buttons" },
                      [
                        _c(
                          "div",
                          { staticClass: "button", on: { click: _vm.goBuy } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isBuy == "buy" ? "立即开通" : "立即续费"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-link",
                          {
                            staticClass: "text-button",
                            attrs: { type: "primary" },
                            on: { click: _vm.goList },
                          },
                          [_vm._v("查询订单列表>")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        { attrs: { hideCard: true } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "x-fc", staticStyle: { "padding-top": "50px" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex1",
                    staticStyle: { "margin-left": "100px" },
                  },
                  _vm._l(_vm.introductionList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "y-start marB20" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "fontBold",
                            staticStyle: { "margin-bottom": "10px" },
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                        _c("div", { staticStyle: { "margin-left": "8px" } }, [
                          _vm._v(_vm._s(item.content)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c("el-image", {
                  staticClass: "marR50",
                  staticStyle: { width: "35%", height: "80%" },
                  attrs: {
                    src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-19/f3d3f1a4585a4924b73da2090b47f311.png",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }