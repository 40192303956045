<template>
  <div>
    <div v-if="data.style.type == 'singleton'" class="singleton">
      <div
        v-for="(item, index) in imgList"
        :key="index"
        :style="{
          padding: `${data.style.marT}px ${data.style.pageBackGauge}px ${data.style.marB}px ${data.style.pageBackGauge}px`,
        }"
      >
        <el-image
          :src="item.url"
          :style="{
            borderRadius: data.style.borderRadius
              ? `${data.style.borderRadius}px`
              : '0px',
            height: `${data.style.imgHeight}px`,
            width: '100%',
          }"
        >
        </el-image>
      </div>
    </div>
    <div
      v-else-if="data.style.type == 'swiper'"
      class="swiper"
      :style="{
        padding: `${data.style.marT}px ${data.style.pageBackGauge}px ${data.style.marB}px ${data.style.pageBackGauge}px`,
      }"
    >
      <el-carousel
        :interval="data.style.interval"
        :height="`${data.style.imgHeight}px`"
      >
        <el-carousel-item v-for="(item, index) in imgList" :key="index">
          <el-image
            :src="item.url"
            :style="{
              borderRadius: data.style.borderRadius
                ? `${data.style.borderRadius}px`
                : '0px',
              height: `${data.style.imgHeight}px`,
              width: '100%',
            }"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="rollBox" v-else-if="data.style.type == 'roll'" :style="style">
      <div :style="{ width: `calc(100% - ${data.style.pageBackGauge * 2}px)` }">
        <el-scrollbar>
          <el-image
            :src="item.url"
            style="width: 100%; margin-right: 10px"
            v-for="(item, index) in imgList"
            :key="index"
            :style="[
              imgStyle,
              { marginLeft: index > 0 ? `${style.imgbackGuge}px` : '' },
              {
                borderRadius: data.style.borderRadius
                  ? `${data.style.borderRadius}px`
                  : '0px',
                height: `${data.style.imgHeight}px`,
              },
            ]"
          >
          </el-image>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "leftTemplate",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  model: { prop: "value", event: "modelValue" },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("modelValue", e);
      },
    },
    imgList() {
      let { imgList } = this.data.style;
      return imgList;
    },
    // 父元素样式
    style() {
      let { style } = this.data;
      let _style = {};
      if (this.data.style.type == "roll") {
        _style.overflowX = "scroll";
        _style.overflow = "hidden";
        _style.whiteSpace = "nowrap";
        _style.marginLeft = `${style.pageBackGauge}px`;
        _style.marginRight = `${style.pageBackGauge}px`;
        _style.marginTop = `${style.marT}px`;
        _style.marginBottom = `${style.marB}px`;
        _style.width = "100%";
        _style.fontSize = "0";
        if (style.imgNum > 1) {
        }
      }
      // _style.marginLeft = `${style.pageBackGauge}px`
      return _style;
    },
    // img item 样式
    imgStyle() {
      let { style } = this.data;
      let _style = {};
      if (style.type == "singleton") {
        if (style.imgStyle == "projection") {
          _style.border = " 2px solid #eeeeee";
        }
      } else if (style.type == "roll") {
        _style.width = `90%`;
        if (style.imgStyle == "projection") {
          _style.border = " 2px solid #eeeeee";
        }

        if (style.imgNum == 2) {
          _style.width = "55%";
        } else if (style.imgNum == 3) {
          _style.width = "33%";
        } else if (style.imgNum == 4) {
          _style.width = "25%";
        } else if (style.imgNum == 5) {
          _style.width = "20%";
        }
        if (style.imgNum > 1) {
          _style.height = "60px";
        }
      }
      return _style;
    },
  },
};
</script>

<style lang="scss" scoped>
.rollBox::-webkit-scrollbar {
  display: none;
}
.singleton,
.swiper {
  .el-image {
    display: block;
  }
}
</style>
