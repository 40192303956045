<template>
  <div class="wrap">
    <buyIndex v-if="active === 1" :active.sync="active" @resume="getResume" @activeInfo='handActiveInfo' />
    <buyStep v-else-if="active === 2" :active.sync="active" :resumeInfo="resumeInfo" :options='options' />
    <buyList v-else-if="active === 3" :active.sync="active" @resume="getResume" :optionsCom='options' />
    <buyRenew v-else-if="active === 4" :active.sync="active" :resumeInfo="resumeInfo" :options='options'
      :parameters='parameters' />
    <buyRenewList v-else-if="active === 5" :active.sync="active" :optionsTenant='optionsTenant'
      @renewParameters='handParameters' />
  </div>
</template>
<script>
import buyRenewList from '@/components/openApplet/buy-renewList.vue'
import {
  getTradeProductOrder,
  getTradeProductToRenew,
  tradeProductRenew,
  getOrderDetail,
  generateOrder,
  orderPay,
  getOrderState,
} from "@/api/O2OThird/cakeFreeSelectApp/activate";
import buyIndex from '@/views/O2OThird/cakeFreeSelectApp/activate/components/buy-index.vue'
import buyList from '@/components/openApplet/buy-list.vue'
import buyStep from '@/components/openApplet/buy-step.vue'
import buyRenew from '@/components/openApplet/buy-Renew.vue'
export default {
  name: 'goBuyActivate',
  components: { buyIndex, buyList, buyStep, buyRenew, buyRenewList },
  data() {
    return {
      active: 1,
      resumeInfo: {},
      parameters: {},
      optionsTenant: {
      },
      options: {
        productAPPType: 4,//1营业通 2裱花间 3点餐 4自选
        getOrderDetailApi: getOrderDetail,
        generateOrderApi: generateOrder,
        orderPayApi: orderPay,
        getOrderStateApi: getOrderState,
        getTradeProductToRenewApi: getTradeProductToRenew,
        tradeProductRenewApi: tradeProductRenew,
        getTradeProductOrderApi: getTradeProductOrder,
      }
    }
  },
  mounted() { },
  methods: {
    handParameters(value) {
      this.parameters = value
      this.active = 4
    },
    handActiveInfo(value) {
      this.optionsTenant = value
      this.active = 5
    },
    getResume(val) {
      this.resumeInfo = val || {}
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  background-color: #eaeaea;
  .top-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
  }
}
</style>
