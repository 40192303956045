var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        { staticClass: "phoneStartBox" },
        [
          _c("el-image", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { src: require("@/assets/images/phoneStart.png") },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "navBar x-f" },
        [
          _c("i", {
            staticClass: "x-f-start",
            staticStyle: { "font-size": "25px", width: "90px" },
          }),
          _c("span", { staticClass: "title" }, [_vm._v("首页")]),
          _c("el-image", {
            staticClass: "capsule x-f-end",
            attrs: { src: require("@/assets/images/capsule.png") },
          }),
        ],
        1
      ),
    ]),
    _c("div", [
      _vm._m(0),
      _vm.$slots.topContent ? _c("div", [_vm._t("topContent")], 2) : _vm._e(),
      _vm._m(1),
      _vm.$slots.bottomContent
        ? _c("div", [_vm._t("bottomContent")], 2)
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "status x-bc" }, [
      _c("div", { staticClass: "x-fc shop" }, [
        _c("img", {
          staticStyle: { width: "20px", height: "20px" },
          attrs: { src: require("@/assets/images/shop.svg"), alt: "#" },
        }),
        _c("span", { staticClass: "marL10" }, [
          _vm._v(" 隼云科技 （默认门店）"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "10px" } }, [
      _c("div", [_vm._v("商品分类")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }