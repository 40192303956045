var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("TablePage", {
        ref: "tablePage",
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
      _c("category", {
        attrs: {
          isSubmit: _vm.isSubmit,
          check: _vm.checkList,
          openCategory: _vm.openCategory,
          isCake: true,
        },
        on: {
          "update:openCategory": function ($event) {
            _vm.openCategory = $event
          },
          "update:open-category": function ($event) {
            _vm.openCategory = $event
          },
          openCategory: function ($event) {
            return _vm.getPitchData($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }