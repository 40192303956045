var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapIndex" },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "开通点餐小程序" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              {
                staticClass: "x-x padd10",
                staticStyle: { padding: "10px 15px" },
              },
              [
                _c("el-image", {
                  staticStyle: { width: "130px", height: "130px" },
                  attrs: {
                    src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-04-23/eb6aa8364ce94a6891af6ec6ac90d7ec.png",
                  },
                }),
                _c("div", { staticClass: "marL20 flex1 y-b" }, [
                  _c("div", { staticStyle: { "line-height": "1.5" } }, [
                    _vm._v(
                      " 隼云点餐小程序是隼云针对微信平台推出的点餐小程序。用户可以随时随地在该小程序中浏览店内的菜单，选择喜欢的食物，可以提前点单或者到店点餐。还支持微信支付和会员优惠券等功能。省去了繁琐的纸质菜单填写过程，帮助餐饮商家提高了运营效率和服务质量，大大减少开店成本，节约人力物力。 "
                    ),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "marB10" }, [
                      _c("span", { staticClass: "annotateGrey fontS14" }, [
                        _vm._v("价格："),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "fontBold fontS14",
                          staticStyle: { color: "#fe5a34" },
                        },
                        [_vm._v("￥")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "fontBold fontS25",
                          staticStyle: { color: "#fe5a34" },
                        },
                        [_vm._v(" " + _vm._s(_vm.price))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "fontBold fontS14",
                          staticStyle: { color: "#fe5a34" },
                        },
                        [_vm._v(" " + _vm._s("/" + _vm.priceLabel))]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "buttons" },
                      [
                        _c(
                          "div",
                          { staticClass: "button", on: { click: _vm.goBuy } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isBuy == "buy" ? "立即开通" : "立即续费"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "el-link",
                          {
                            staticClass: "text-button",
                            attrs: { type: "primary" },
                            on: { click: _vm.goList },
                          },
                          [_vm._v("查询订单列表>")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        { attrs: { hideCard: true } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "x-fc" },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex1",
                    staticStyle: { "margin-left": "100px" },
                  },
                  _vm._l(_vm.introductionList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "y-start marB20" },
                      [
                        _c("div", { staticClass: "fontBold" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _c("div", { staticStyle: { "margin-left": "8px" } }, [
                          _vm._v(_vm._s(item.content)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c("el-image", {
                  staticClass: "marR50",
                  staticStyle: { width: "41%", height: "80%" },
                  attrs: {
                    src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-04-23/893c8ec54a90478a89b04f16956c554d.png",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }