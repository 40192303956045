<template>
  <div>
    <div v-if="data.style.type == 'singleton'" class="singleton">
      <div
        v-for="(item, index) in imgList"
        :key="index"
        :style="{
          padding: `${data.style.marT}px ${data.style.pageBackGauge}px ${data.style.marB}px ${data.style.pageBackGauge}px`,
        }"
      >
        <el-image
          :src="item.url"
          :style="{
            borderRadius: data.style.borderRadius
              ? `${data.style.borderRadius}px`
              : '0px',
            height: `${data.style.imgHeight}px`,
            width: '100%',
          }"
        >
        </el-image>
      </div>
    </div>
    <div
      v-else-if="data.style.type == 'swiper'"
      class="swiper"
      :style="{
        padding: `${data.style.marT}px ${data.style.pageBackGauge}px ${data.style.marB}px ${data.style.pageBackGauge}px`,
      }"
    >
      <el-carousel
        :interval="data.style.interval"
        :height="`${data.style.imgHeight}px`"
      >
        <el-carousel-item v-for="(item, index) in imgList" :key="index">
          <el-image
            :src="item.url"
            :style="{
              borderRadius: data.style.borderRadius
                ? `${data.style.borderRadius}px`
                : '0px',
              height: `${data.style.imgHeight}px`,
              width: '100%',
            }"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--  -->
    <div
      v-if="data.style.type == 'classification'"
      :style="{
        padding: `${data.style.marT}px ${data.style.pageBackGauge}px 0px ${data.style.pageBackGauge}px`
      }"
      class="yihangyige"
    >
      <div
        v-for="(item, index) in imgList"
        :key="index"
        :style="{
          padding: `0px 0px ${data.style.marB}px 0px`,
        }"
      >
        <div
          class="li"
          :style="{
            borderRadius: `${data.style.borderRadius}px`,
          }"
          
        >
          <el-image
            class="image"
            :src="item.url"
            style="display: block;margin: 0 auto;"
          >
          </el-image>
          <div
            v-if="data.style.showTitle"
            :style="{
              textAlign: `${data.style.alignment? 'left': 'center'}`,
              textIndent: `${data.style.alignment? '10px': ''}`,
              fontWeight: `${data.style.isBold? 'bold': '400'}`,
            }"
            style="margin-top: 10px;"
          >商品名称</div>
        </div>
      </div>
    </div>

    <!-- ==== -->
    <div
      v-if="['lianghangpailie', 'sanhangpailie'].includes(data.style.type)"
      :style="{
        padding: `${data.style.marT}px ${data.style.pageBackGauge - data.style.pageBackGauge? 10 : 0}px 0px ${data.style.pageBackGauge - data.style.pageBackGauge? 10 : 0}px`
      }" 
      :class="data.style.type === 'sanhangpailie'? 'sanhangpailie' : 'lianghangpailie'">
      <div
        v-for="(item, index) in imgList"
        class="li"
        :key="index"
        :style="{
          marginBottom: `${data.style.marB}px`,
          flex: data.style.type == 'lianghangpailie'? `0 0 calc(100% /2 - ${data.style.pageBackGauge + 20}px` : `0 0 calc(100% /3 - ${data.style.pageBackGauge + 30}px`,
        }"
        style="background: #f7f7f7;"
      >
        <div :style="{
          borderRadius: data.style.borderRadius
            ? `${data.style.borderRadius}px`
            : '0px',}" 
          style="background: #fff;padding: 10px;"
        >
          <el-image
            :src="item.url"
            class="image"
            style="display: block;margin: 0 auto;"
          >
          </el-image>
          <div
            v-if="data.style.showTitle"
            :style="{
              textAlign: `${data.style.alignment? 'left': 'center'}`,
              fontWeight: `${data.style.isBold? 'bold': '400'}`,
            }"
            style="margin-bottom: 10px;margin-top: 10px;"
          >商品名称</div>
        </div>
      </div>
    </div>
 
    <div
      v-if="data.style.type === 'datuxianshi'"
      :style="{
        padding: `${data.style.marT}px ${data.style.pageBackGauge}px 0px ${data.style.pageBackGauge}px`
      }"
      class="datuxianshi"
    >
      <div
        v-for="(item, index) in imgList"
        :key="index"
        :style="{
          padding: `0px 0px ${data.style.marB}px 0px`
        }"
      >
        <div
          class="li"
          :style="{
              borderRadius: data.style.showTitle ? `${data.style.borderRadius}px ${data.style.borderRadius}px 0 0 ` : `${data.style.borderRadius}px`,
            }"
        >
          <el-image
            class="image"
            :src="item.url"
            style="display: block;margin: 0 auto;"
            :style="{
              borderRadius: data.style.showTitle ? `${data.style.borderRadius}px ${data.style.borderRadius}px 0 0 ` : `${data.style.borderRadius}px`,
            }"
          >
          </el-image>
        </div>
        <div
          v-if="data.style.showTitle"
          :style="{
            borderRadius: `0 0 ${data.style.borderRadius}px ${data.style.borderRadius}px`,
            textAlign: `${data.style.alignment? 'left': 'center'}`,
            fontWeight: `${data.style.isBold? 'bold': '400'}`,
            background: `#fff`
          }"
          class="title"
        >商品名称</div>
      </div>
    </div>
    <!-- 横向滑动 -->
    <div v-if="data.style.type === 'hengxianggundong'" class="swipeBox x-bc">
      <el-scrollbar>
        <div
          v-for="(item, index) in imgList"
          :key="index"
          :style="{
            width: `calc(100% - ${data.style.pageBackGauge}px)`,
            padding: `${data.style.marT}px ${data.style.pageBackGauge}px 0px ${data.style.pageBackGauge}px`,
          }"
        >
          <el-image
            :src="item.url"
            :style="{
              borderRadius: data.style.borderRadius
                ? `${data.style.borderRadius}px`
                : '0px',
            }"
            style="width: 120px;height: 120px;"
          >
          </el-image>
          <div v-if="data.style.showTitle"
            :style="{
              textAlign: `${data.style.alignment? 'left': 'center'}`,
              fontWeight: `${data.style.isBold? 'bold': '400'}`,
              padding: `${data.style.marB}px 0px 0px 0px`,
            }"
            >商品名称</div>
        </div>
      </el-scrollbar>
    </div>
    
    <!-- <div class="rollBox" v-else-if="data.style.type == 'roll'" :style="style">
      <div :style="{ width: `calc(100% - ${data.style.pageBackGauge * 2}px)` }">
        <el-scrollbar>
          <el-image
            :src="item.url"
            style="width: 100%; margin-right: 10px"
            v-for="(item, index) in imgList"
            :key="index"
            :style="[
              imgStyle,
              { marginLeft: index > 0 ? `${style.imgbackGuge}px` : '' },
              {
                borderRadius: data.style.borderRadius
                  ? `${data.style.borderRadius}px`
                  : '0px',
                height: `${data.style.imgHeight}px`,
              },
            ]"
          >
          </el-image>
        </el-scrollbar>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "leftTemplate",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  model: { prop: "value", event: "modelValue" },
  computed: {
    data: {
      get() {
        console.log(this.value, 'value')
        return this.value;
      },
      set(e) {
        this.$emit("modelValue", e);
      },
    },
    imgList() {
      let { imgList } = this.data.style;
      return imgList;
    },
    // 父元素样式
    style() {
      let { style } = this.data;
      let _style = {};
      if (this.data.style.type == "roll") {
        _style.overflowX = "scroll";
        _style.overflow = "hidden";
        _style.whiteSpace = "nowrap";
        _style.marginLeft = `${style.pageBackGauge}px`;
        _style.marginRight = `${style.pageBackGauge}px`;
        _style.marginTop = `${style.marT}px`;
        _style.marginBottom = `${style.marB}px`;
        _style.width = "100%";
        _style.fontSize = "0";
        if (style.imgNum > 1) {
        }
      }
      // _style.marginLeft = `${style.pageBackGauge}px`
      return _style;
    },
    // img item 样式
    imgStyle() {
      let { style } = this.data;
      let _style = {};
      if (style.type == "singleton") {
        if (style.imgStyle == "projection") {
          _style.border = " 2px solid #eeeeee";
        }
      } else if (style.type == "roll") {
        _style.width = `90%`;
        if (style.imgStyle == "projection") {
          _style.border = " 2px solid #eeeeee";
        }

        if (style.imgNum == 2) {
          _style.width = "55%";
        } else if (style.imgNum == 3) {
          _style.width = "33%";
        } else if (style.imgNum == 4) {
          _style.width = "25%";
        } else if (style.imgNum == 5) {
          _style.width = "20%";
        }
        if (style.imgNum > 1) {
          _style.height = "60px";
        }
      }
      return _style;
    },
  },
};
</script>

<style lang="scss" scoped>
.rollBox::-webkit-scrollbar {
  display: none;
}
.singleton,
.swiper {
  .el-image {
    display: block;
  }
}
// 一行两个
.small {
  display: flex;
  flex-wrap: wrap;
}

.swipeBox {
  //滚动条
  ::v-deep .el-scrollbar__wrap {
    overflow-x: auto;
  }

  ::v-deep .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
    white-space: nowrap;
    display: flex;
  }

  .goodsItem {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 30%;
  }
}
.yihangyige {
  padding: 10px 10px 0px 10px;
  .li {
    text-align: center;
    background: #fff;
    border-radius: 10px;
    padding: 20px 0 15px 0;
  }
  .image {
    width: 100px;
    height: 100px;
    margin-top: 20px;
    margin: 20px 0 10px 0;
  }
}
.lianghangpailie, .sanhangpailie {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    justify-content: space-between;
    .li {
      // margin: 10px 10px 10px 10px;
      margin: 0px 10px 0px 10px;
      text-align: center;
      background: #fff;
      border-radius: 10px;
      // padding: 20px 0 20px 0;
      flex: 0 0 47%;
    }

    .image {
      // width: 100px;
      height: 100px;
      margin-top: 20px;
      margin: 20px 0 10px 0;
    }
  }
  .sanhangpailie{
    .li{
      flex: 0 0 calc((100% - 60px)/3) !important;
    }
  }
  .datuxianshi {
    padding: 20px 20px 0px 20px;
    .li {
      // margin: 10px 10px 0px 10px;
      text-align: center;
      background: #fff;
      border-radius: 10px 10px 0 0;
      padding: 0;
      flex: 0 0 47%;
    }

    .image {
      width: 100%;
      height: 400px;
      margin-top: 20px;
      margin: 20px 0 10px 0;
    }
    .title {
      background: #f7f7f7;
      padding: 20px;
      border-radius: 0 0 10px 10px ;
      text-indent: 20px;
    }
  }
</style>
