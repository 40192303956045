<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
      <el-table-column type="expand" fixed slot="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="订单ID">
              <span>{{ props.row.orderId }}</span>
            </el-form-item>
            <el-form-item label="订单类型">
              <span>{{ props.row.orderTypeName }}</span>
            </el-form-item>
            <el-form-item label="商品名称">
              <span>{{ props.row.skuName }}</span>
            </el-form-item>
            <el-form-item label="数量">
              <span>{{ props.row.skuQty }}</span>
            </el-form-item>
            <el-form-item label="原价金额">
              <span>{{ props.row.originalAmount }}</span>
            </el-form-item>
            <el-form-item label="实付金额">
              <span>{{ props.row.payAmount }}</span>
            </el-form-item>
            <el-form-item label="订单状态">
              <span>{{ props.row.orderStatusName }}</span>
            </el-form-item>
            <el-form-item label="支付优惠">
              <span>{{ props.row.paymentDiscount }}</span>
            </el-form-item>
            <el-form-item label="创建时间">
              <span>{{ props.row.createOrderTime }}</span>
            </el-form-item>
            <el-form-item label="支付时间">
              <span>{{ props.row.payTime }}</span>
            </el-form-item>
            <el-form-item label="联系人">
              <span>{{ props.row.contactName }}</span>
            </el-form-item>
            <el-form-item label="联系电话">
              <span>{{ props.row.contactPhone }}</span>
            </el-form-item>
            <el-form-item label="用户ID">
              <span>{{ props.row.openId }}</span>
            </el-form-item>
          </el-form>
          <el-table
            ref="multipleTable"
            tooltip-effect="dark"
            :data="props.row.itemList"
            border
            max-height="300"
            height="300"
          >
            <el-table-column
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column
              label="卷ID"
              align="center"
              prop="certificateId"
              min-width="160"
            />
            <el-table-column
              label="状态"
              align="center"
              prop="itemStatusName"
              min-width="100"
            />
            <el-table-column
              label="退款金额"
              align="center"
              prop="refundAmount"
              min-width="120"
            />
            <el-table-column
              label="退款时间"
              align="center"
              prop="refundTime"
              min-width="155"
            />
            <el-table-column
              label="卷订单ID"
              align="center"
              prop="orderId"
              min-width="160"
            />
            <el-table-column
              label="更新时间"
              align="center"
              prop="itemUpdateTime"
              min-width="155"
            />
          </el-table>
        </template>
      </el-table-column>
    </TablePage>
  </div>
</template>

<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  douYinOrderList,
  synchronizationReset
} from '@/api/O2OThird/douyin/order'
import { getDicts } from '@/api/system/dict/data'
export default {
  name: 'DouYinOrder',
  dicts: ['douyin_order_status'],
  components: { TablePage },
  data () {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '抖音订单',
        getListApi: douYinOrderList,
        listNo: true, // 序号
        rowKey: 'orderId',
        //搜索
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'input',
            label: '商品名称',
            tip: '请输入商品名称',
            seniorSearch: true,
            model: '',
            clearable: true,
            filter: 'skuName'
          },
          {
            type: 'local',
            label: '订单状态',
            model: '',
            filter: 'orderStatus',
            seniorSearch: true,
            option: {
              clearable: true,
              remote: getDicts,
              remoteBody: 'douyin_order_status',
              label: 'dictLabel',
              value: 'dictValue'
            }
          },
          {
            type: 'button',
            tip: '查询',
            btnType: 'primary',
            click: 'search',
            isRestore: true
          },
          { type: 'button', tip: '同步并查询', click: 'synchronizationReset' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        //表格
        columns: [
          {
            prop: 'expand',
            type: 'slot'
          },
          {
            prop: 'orderId',
            label: '订单ID',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'orderTypeName',
            label: '订单类型',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'poiName',
            label: '抖音门店',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'skuName',
            label: '商品名称',
            minWidth: 220,
            align: 'center'
          },
          {
            prop: 'skuQty',
            label: '数量',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'originalAmount',
            label: '原价金额',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'payAmount',
            label: '实付金额',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'orderStatusName',
            label: '订单状态',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'paymentDiscount',
            label: '支付优惠',
            minWidth: 150,
            align: 'center'
          }
        ]
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'synchronizationReset':
          try {
            this.options.loading = true
            await this.$refs.tablePage.setBody()
            await synchronizationReset(this.options.body)
            this.$refs.tablePage.getList()
            this.$message.success('同步成功')
          } catch (error) {
          } finally {
            this.options.loading = false
          }
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}
::v-deep .demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
::v-deep .demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 20%;
}
</style>
