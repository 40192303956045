var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("topOperatingButton", {
        attrs: {
          isSubmitAddBtn: false,
          isAuditBillBtn: false,
          isQuitBtn: false,
          id: "topOperatingButton",
        },
        on: { submitForm: _vm.submitForm },
      }),
      _c(
        "cardTitleCom",
        {
          staticStyle: { "margin-bottom": "0px", width: "100%" },
          attrs: { cardTitle: "蛋糕自选首页" },
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "marT20 x-center" }, [
              _c(
                "div",
                { staticClass: "flex1 x-c-end" },
                [
                  _c(
                    "ConterPreview",
                    { staticClass: "conterPreview" },
                    [
                      _c("template", { slot: "topContent" }, [
                        _c(
                          "div",
                          {
                            staticClass: "cursorP",
                            on: {
                              click: function ($event) {
                                return _vm.upActive(0)
                              },
                            },
                          },
                          [
                            _c("LeftTemplate", {
                              model: {
                                value: _vm.homeForm.templateContent[0],
                                callback: function ($$v) {
                                  _vm.$set(_vm.homeForm.templateContent, 0, $$v)
                                },
                                expression: "homeForm.templateContent[0]",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("template", { slot: "bottomContent" }, [
                        _c(
                          "div",
                          {
                            staticClass: "cursorP",
                            on: {
                              click: function ($event) {
                                return _vm.upActive(1)
                              },
                            },
                          },
                          [
                            _c("LeftTemplate", {
                              model: {
                                value: _vm.homeForm.templateContent[1],
                                callback: function ($$v) {
                                  _vm.$set(_vm.homeForm.templateContent, 1, $$v)
                                },
                                expression: "homeForm.templateContent[1]",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { width: "50%" } },
                [
                  _c(
                    "div",
                    { staticClass: "fontBold x-x" },
                    [
                      _c("span", { staticClass: "marR20" }, [
                        _vm._v(_vm._s("当前类型：")),
                      ]),
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-bottom": "30px" },
                          model: {
                            value: _vm.active,
                            callback: function ($$v) {
                              _vm.active = $$v
                            },
                            expression: "active",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: 0 } }, [
                            _vm._v("轮播图片"),
                          ]),
                          _c("el-radio-button", { attrs: { label: 1 } }, [
                            _vm._v("分类图片"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("RightStatue", {
                    staticClass: "rightStatue flex1",
                    on: { setTemp: _vm.getTemp },
                    model: {
                      value: _vm.homeForm.templateContent[_vm.active],
                      callback: function ($$v) {
                        _vm.$set(_vm.homeForm.templateContent, _vm.active, $$v)
                      },
                      expression: "homeForm.templateContent[active]",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }