<template>
  <div class="wrap">
    <cardTitleCom cardTitle="抖音服务设置" class="setUp">
      <template #rightCardTitle>
        <el-button class="marR10" type="primary" size="mini" @click="getSave"
          >保存</el-button
        >
      </template>
      <template slot="cardContent">
        <el-form
          ref="douyinParamForm"
          :model="douyinParamForm"
          :rules="rules"
          label-width="160px"
        >
          <el-form-item
            label="APPID"
            prop="douyin_SYSTEM_CONFIG_O2O_11"
            class="marT20"
          >
            <el-input
              class="inputWidth"
              size="mini"
              v-model="douyinParamForm.douyin_SYSTEM_CONFIG_O2O_11"
              placeholder="请输入APPID"
            ></el-input>
          </el-form-item>
          <el-form-item label="AppSecret" prop="douyin_SYSTEM_CONFIG_O2O_12">
            <el-input
              class="inputWidth"
              size="mini"
              v-model="douyinParamForm.douyin_SYSTEM_CONFIG_O2O_12"
              placeholder="请输入AppSecret"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="抖音来客账户ID"
            prop="douyin_SYSTEM_CONFIG_O2O_13"
          >
            <el-input
              class="inputWidth"
              size="mini"
              v-model="douyinParamForm.douyin_SYSTEM_CONFIG_O2O_13"
              placeholder="请输入抖音来客账户ID"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="抖音来客账户名称"
            prop="douyin_SYSTEM_CONFIG_O2O_14"
          >
            <el-input
              class="inputWidth"
              size="mini"
              v-model="douyinParamForm.douyin_SYSTEM_CONFIG_O2O_14"
              placeholder="请输入抖音来客账户名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="抖音服务地址" prop="douyin_SYSTEM_CONFIG_O2O_15">
            <el-input
              class="inputWidth"
              size="mini"
              v-model="douyinParamForm.douyin_SYSTEM_CONFIG_O2O_15"
              placeholder="请输入抖音服务地址"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  getSysDouyinParamConfig,
  saveSysDouyinParamConfig
} from '@/api/O2OThird/douyin/param' //门店订货
import { create } from 'sortablejs'
export default {
  name: 'Param',
  components: {
    cardTitleCom
  },
  data () {
    return {
      douyinParamForm: {},
      //表单校验
      rules: {
        douyin_SYSTEM_CONFIG_O2O_11: [
          {
            required: true,
            message: '请输入APPID',
            trigger: ['blur', 'change']
          }
        ],
        douyin_SYSTEM_CONFIG_O2O_12: [
          {
            required: true,
            message: '请输入AppSecret',
            trigger: ['blur', 'change']
          }
        ],
        douyin_SYSTEM_CONFIG_O2O_13: [
          {
            required: true,
            message: '请输入抖音来客账户ID',
            trigger: ['blur', 'change']
          }
        ],
        douyin_SYSTEM_CONFIG_O2O_14: [
          {
            required: true,
            message: '抖音来客账户名称',
            trigger: ['blur', 'change']
          }
        ],
        douyin_SYSTEM_CONFIG_O2O_15: [
          {
            required: true,
            message: '请输入抖音服务地址',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  async created () {
    const res = await getSysDouyinParamConfig()
    if (res?.data) {
      this.douyinParamForm = res.data
    }
  },
  methods: {
    async getSave () {
      try {
        const valid = await this.$refs['douyinParamForm'].validate()
        if (valid) {
          const res = await saveSysDouyinParamConfig(this.douyinParamForm)
          this.$message.success('保存成功')
        }
      } catch (error) {
        this.$message.error('保存失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .setUp {
    height: 100%;
  }
}
</style>
