var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.data.style.type == "singleton"
      ? _c(
          "div",
          { staticClass: "singleton" },
          _vm._l(_vm.imgList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                style: {
                  padding:
                    _vm.data.style.marT +
                    "px " +
                    _vm.data.style.pageBackGauge +
                    "px " +
                    _vm.data.style.marB +
                    "px " +
                    _vm.data.style.pageBackGauge +
                    "px",
                },
              },
              [
                _c("el-image", {
                  style: {
                    borderRadius: _vm.data.style.borderRadius
                      ? _vm.data.style.borderRadius + "px"
                      : "0px",
                    height: _vm.data.style.imgHeight + "px",
                    width: "100%",
                  },
                  attrs: { src: item.url },
                }),
              ],
              1
            )
          }),
          0
        )
      : _vm.data.style.type == "swiper"
      ? _c(
          "div",
          {
            staticClass: "swiper",
            style: {
              padding:
                _vm.data.style.marT +
                "px " +
                _vm.data.style.pageBackGauge +
                "px " +
                _vm.data.style.marB +
                "px " +
                _vm.data.style.pageBackGauge +
                "px",
            },
          },
          [
            _c(
              "el-carousel",
              {
                attrs: {
                  interval: _vm.data.style.interval,
                  height: _vm.data.style.imgHeight + "px",
                },
              },
              _vm._l(_vm.imgList, function (item, index) {
                return _c(
                  "el-carousel-item",
                  { key: index },
                  [
                    _c("el-image", {
                      style: {
                        borderRadius: _vm.data.style.borderRadius
                          ? _vm.data.style.borderRadius + "px"
                          : "0px",
                        height: _vm.data.style.imgHeight + "px",
                        width: "100%",
                      },
                      attrs: { src: item.url },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm.data.style.type == "roll"
      ? _c("div", { staticClass: "rollBox", style: _vm.style }, [
          _c(
            "div",
            {
              style: {
                width:
                  "calc(100% - " + _vm.data.style.pageBackGauge * 2 + "px)",
              },
            },
            [
              _c(
                "el-scrollbar",
                _vm._l(_vm.imgList, function (item, index) {
                  return _c("el-image", {
                    key: index,
                    staticStyle: { width: "100%", "margin-right": "10px" },
                    style: [
                      _vm.imgStyle,
                      {
                        marginLeft:
                          index > 0 ? _vm.style.imgbackGuge + "px" : "",
                      },
                      {
                        borderRadius: _vm.data.style.borderRadius
                          ? _vm.data.style.borderRadius + "px"
                          : "0px",
                        height: _vm.data.style.imgHeight + "px",
                      },
                    ],
                    attrs: { src: item.url },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }